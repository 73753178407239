var SMARTIFYAPPS = SMARTIFYAPPS || {};
SMARTIFYAPPS.rv = SMARTIFYAPPS.rv || {};

!function(e){var n=!1;if("function"==typeof define&&define.amd&&(define(e),n=!0),"object"==typeof exports&&(module.exports=e(),n=!0),!n){var o=window.Cookies,t=window.Cookies=e();t.noConflict=function(){return window.Cookies=o,t}}}(function(){function e(){for(var e=0,n={};e<arguments.length;e++){var o=arguments[e];for(var t in o)n[t]=o[t]}return n}function n(o){function t(n,r,i){var c;if("undefined"!=typeof document){if(arguments.length>1){if(i=e({path:"/"},t.defaults,i),"number"==typeof i.expires){var a=new Date;a.setMilliseconds(a.getMilliseconds()+864e5*i.expires),i.expires=a}try{c=JSON.stringify(r),/^[\{\[]/.test(c)&&(r=c)}catch(e){}return r=o.write?o.write(r,n):encodeURIComponent(String(r)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,decodeURIComponent),n=encodeURIComponent(String(n)),n=n.replace(/%(23|24|26|2B|5E|60|7C)/g,decodeURIComponent),n=n.replace(/[\(\)]/g,escape),document.cookie=[n,"=",r,i.expires?"; expires="+i.expires.toUTCString():"",i.path?"; path="+i.path:"",i.domain?"; domain="+i.domain:"",i.secure?"; secure":""].join("")}n||(c={});for(var p=document.cookie?document.cookie.split("; "):[],s=/(%[0-9A-Z]{2})+/g,d=0;d<p.length;d++){var f=p[d].split("="),u=f.slice(1).join("=");'"'===u.charAt(0)&&(u=u.slice(1,-1));try{var l=f[0].replace(s,decodeURIComponent);if(u=o.read?o.read(u,l):o(u,l)||u.replace(s,decodeURIComponent),this.json)try{u=JSON.parse(u)}catch(e){}if(n===l){c=u;break}n||(c[l]=u)}catch(e){}}return c}}return t.set=t,t.get=function(e){return t.call(t,e)},t.getJSON=function(){return t.apply({json:!0},[].slice.call(arguments))},t.defaults={},t.remove=function(n,o){t(n,"",e(o,{expires:-1}))},t.withConverter=n,t}return n(function(){})});if (typeof Cookies !== 'undefined') {
    SMARTIFYAPPS.rv.Cookies = Cookies.noConflict();
}

SMARTIFYAPPS.rv.Cookie = SMARTIFYAPPS.rv.Cookie || {
    name: 'scm_reviews_importer_app',
    count: 'scm_reviews_importer_count',
    setInstalled: function() {
        SMARTIFYAPPS.rv.Cookies.set(this.count, 0);
        SMARTIFYAPPS.rv.Cookies.set(this.name, "installed");
    },
    isInstalled: function() {
        var count = SMARTIFYAPPS.rv.Cookies.get(this.count);
        if (! count) count = 0;
        count++;
        SMARTIFYAPPS.rv.Cookies.set(this.count, count);

                    return SMARTIFYAPPS.rv.Cookies.get(this.name) === "installed" && count < 2;
            }
};

SMARTIFYAPPS.rv.performanceNow = SMARTIFYAPPS.rv.performanceNow || function() {
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        return Math.round(performance.now()) + ' ms ';
    } else {
        return '';
    }
};

    var scripts = document.head.getElementsByTagName('script');
    for (var i = 0; i < scripts.length; ++i) {
        if(scripts[i].innerText.indexOf('asyncLoad') >= 0 &&
            (scripts[i].innerText.indexOf("reviews.smartifyapps.com\\/storage\\/scripts") >= 0 ||
             scripts[i].innerText.indexOf("d1bu6z2uxfnay3.cloudfront.net\\/storage\\/scripts") >= 0
            )
        ) {
            console.log('already has scripttag, load RV');
            SMARTIFYAPPS.rv.installed = true;
            SMARTIFYAPPS.rv.Cookie.setInstalled();
        }
    }
    if (! SMARTIFYAPPS.rv.Cookie.isInstalled()) {
        SMARTIFYAPPS.rv.installed = false;
        console.log(SMARTIFYAPPS.rv.performanceNow() + 'preload skip');
    } else {
        SMARTIFYAPPS.rv.installed = true;
    }

SMARTIFYAPPS.rv.jqVersion = SMARTIFYAPPS.rv.jqVersion || function(jQuery) {
    return jQuery.fn.jquery.replace(/\.(\d)/g,".0$1").replace(/\.0(\d{2})/g,".$1");
};

if (SMARTIFYAPPS.rv.loadingApp !== true && SMARTIFYAPPS.rv.installed === true) {
    SMARTIFYAPPS.rv.loadingApp = true;

    SMARTIFYAPPS.rv.loadScript = SMARTIFYAPPS.rv.loadScript || function(url, callback){
        var script = document.createElement("script");
        script.type = "text/javascript";
        if (script.readyState){  // IE
            script.onreadystatechange = function(){
                if (script.readyState == "loaded" || script.readyState == "complete"){
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {  // Others
            script.onreadystatechange = callback;
            script.onload = callback;
        }

        script.src = url;
        var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(script, x);
    };

    SMARTIFYAPPS.rv.loadStyle = SMARTIFYAPPS.rv.loadStyle || function(url){
        var head = document.getElementsByTagName("head")[0];
        var extension = '.css';
        var link = document.createElement("link");
        link.href = url;
        link.type = "text/css";
        link.rel = "stylesheet";
        head.appendChild(link);
    };
                    SMARTIFYAPPS.rv.scmReviewsRate = SMARTIFYAPPS.rv.scmReviewsRate || (function() {
  function loadCss(filename) {
    var cssNode = document.createElement('link');
    cssNode.setAttribute('rel', 'stylesheet');
    cssNode.setAttribute('type', 'text/css');
    cssNode.setAttribute('href', filename);
    document.getElementsByTagName('head')[0].appendChild(cssNode);
  }

  function getInfoShop() {
    var infoShop = document.getElementById('scm-reviews-shopSetting').getAttribute('data-shop');
    return parseJson(infoShop);
  }

  function addStyleOnHead(infoShopJson) {
    let styleStar = '';
    let widthContainer = infoShopJson.width ? infoShopJson.width : '1200px';
    switch (infoShopJson.starStyle) {
      case 'heart':
        styleStar = 'f004';
        break;
      case 'like':
        styleStar = 'f164';
        break;
      case 'smile':
        styleStar = 'f118';
        break;
      default:
        styleStar = 'f005';
    }
    let styleConfig = `
            .scm-product-detail-rate-custom .fa-star:before,.scm-reviews-rate .fa-star:before {
                content: "\\${styleStar}";
            }
            .scm-product-detail-rate-custom i, .scm-reviews-rate i{
            	color: ${infoShopJson.starColor};
          	}
            .scm-container{
                max-width: ${widthContainer}
            }
        `;
    var linkElement = this.document.createElement('link');
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('href', 'data:text/css;charset=UTF-8,' + encodeURIComponent(styleConfig));
    document.querySelector('head').appendChild(linkElement);
  }

  function getElement() {
    var x = document.getElementsByClassName('scm-reviews-rate');
    return [].slice.call(x);
  }

  function pageBilderCustomRatingStarProductPage() {
    let elementCustom = document.getElementsByClassName('scm-product-detail-rate-custom');
    if (!elementCustom || elementCustom.length === 0) {
        return;
    }
  let rate = {
    'average': 0,
    'total': 0
  };
  let elementValue = document.getElementsByClassName('scm-value-rate-product');
    if (!elementValue || elementValue.length === 0) {
    return;
    }
    let rateVersion2 = elementValue[0].getAttribute('data-rate-version2');
    if (rateVersion2 && rateVersion2 != 'null') {
      rateVersion2 = JSON.parse(rateVersion2);
      rate = {
        'average': rateVersion2['average'],
        'total': rateVersion2['total']
      };
    }
    if (rate && rate.average > 0) {
      let blockStar = scmReviewsRate(rate.average);
      blockStar += `<span> (${rate.total}) </span>`;
      elementCustom[0].innerHTML = blockStar;
    }
  }

  function actionCreateReviews() {
    pageBilderCustomRatingStarProductPage();
    let arrayListElement = getElement();
    arrayListElement.forEach(element => {
      let rate = {
        'average': 0,
        'total': 0
      };
      let rateVersion2 = element.getAttribute('data-rate-version2');
      if (rateVersion2 && rateVersion2 != 'null') {
        rateVersion2 = JSON.parse(rateVersion2);
        rate = {
          'average': rateVersion2['average'],
          'total': rateVersion2['total']
        };
      }
      if (rate && rate.average > 0) {
        let blockStar = scmReviewsRate(rate.average);
        blockStar += `<span> (${rate.total}) </span>`;
        element.innerHTML = blockStar;
      }
    });
  }

  function scmReviewsRate(value) {
    let number = Math.floor(value + 0.5);
    let blockStar = '';
    for (let i = 0; i < number; i++) {
      blockStar = blockStar + `<i class="fas fa-star"></i>`;
    }
    for (let i = number; i < 5; i++) {
      blockStar = blockStar + `<i class="far fa-star"></i>`;
    }
    return blockStar;
  }

  function parseJson(value) {
    if (value) {
      let valueDecode64 = atob(value);
      return JSON.parse(valueDecode64);
    } else {
      return value;
    }
  }

  function getUrlParameter(sParam) {
    var sPageURL = location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  }
    function dispatchEventBeforeScrollToWidgetReviews(){
        const eventClickStar = document.createEvent('Event');
        eventClickStar.initEvent('beforeScrollToReviewWidget', true, true);
        document.dispatchEvent(eventClickStar);
    }
  function scrollWigetReview() {
    let wigetEl = document.querySelector('.scm-container.custom');
    let wiget = wigetEl ? wigetEl : document.querySelector('.scm-container');
    wiget.scrollIntoView();
  }

  function onWindowLoad() {
    actionCreateReviews();
    if (getUrlParameter('review')) {
      setTimeout(function() {
        scrollWigetReview();
      }, 0);

    }
  }

  function constructor() {
    let dataShopSetting = getInfoShop();
    addStyleOnHead(dataShopSetting);
    loadCss('https://d1bu6z2uxfnay3.cloudfront.net/css/fontawesome/css/all.min.css?version=21');
  }

  function init() {
    constructor();
    actionCreateReviews();
  }

  return {
    init,
    actionCreateReviews,
    onWindowLoad,
    getUrlParameter,
    scrollWigetReview,
    dispatchEventBeforeScrollToWidgetReviews
  };
})();

            SMARTIFYAPPS.rv.startReviewsImporter = SMARTIFYAPPS.rv.startReviewsImporter || function() {
        console.log(SMARTIFYAPPS.rv.performanceNow() + 'smart aliexpress reviews app starting...');
                    function startReviewImporter() {
  document.querySelectorAll('a[href="#scm-reviews-importer"]').forEach(item => {
    item.addEventListener('click', event => {
        SMARTIFYAPPS.rv.scmReviewsRate.dispatchEventBeforeScrollToWidgetReviews();
        setTimeout(function(){
            SMARTIFYAPPS.rv.scmReviewsRate.scrollWigetReview();
        }, 10);
    })
  });
  document.addEventListener("click", function(e){
    if(e.target == document.getElementsByClassName("scm-popup-before")[0]){
      closePopup();
    }
  });
  const remove = function (e) {
    e.stopPropagation();
    closePopup();
  };
  window.onload = function(){

  };

  function closePopup() {
    var scmpopupContainer = document.getElementById("scm-review-importer-popup");
    scmpopupContainer.classList.remove('show-popup');
    document.getElementById('scm-review-importer-popup').style.display= "none";
  }

  function createPopup(data) {
    let htmlPopup= createPopupHtml(data.content,data.photos);
    var scmpopupContainer = document.getElementById("scm-review-importer-popup");
    scmpopupContainer.classList.add('show-popup');

    var iframeDocument = document.querySelector('#scm-review-importer-popup-iframe').contentWindow.document;
    document.getElementById('scm-review-importer-popup').style.display= "block";
    iframeDocument.open('text/html', 'replace');
    iframeDocument.write(htmlPopup);
    iframeDocument.close();
  }

  function changeHeightIframe(height) {
    const elFrame = document.querySelector(".custom #scm-reviews-importer-iframe");
    const frame = elFrame ? elFrame : document.querySelector(".scm-reviews-importer-iframe");
    frame.height = height + "px";
    frame.parentNode.style.height = height + 'px';
  }

  function receiveMessage(event) {
    if (event.data.type === 'createPopup') {
      createPopup(event.data);
    } else if (event.data === 'removePopup') {
      closePopup();
    } else if (event.data.type === 'changeHeight') {
      changeHeightIframe(event.data.height);
    }
  }

    window.addEventListener('message', receiveMessage, false);
    const elRoot = document.querySelector('.custom #scm-reviews-importer');
    const root= elRoot ? elRoot : document.querySelector('.scm-reviews-importer');
  if(root){
        let iframeEl = document.querySelector('.custom #scm-reviews-importer-iframe');
        let iframeRoot= iframeEl ? iframeEl : document.querySelector('.scm-reviews-importer-iframe');
        let iframeDocument=  iframeRoot.contentWindow.document;
      iframeDocument.open('text/html', 'replace');
      iframeDocument.write(htmlIframe);
      iframeDocument.close();
  }
}

startReviewImporter();
                        SMARTIFYAPPS.rv.scmReviewsRate.init();
                window.onload = (event) => {
                    SMARTIFYAPPS.rv.scmReviewsRate.onWindowLoad();
                };
                    let rootElFrame= document.querySelector(".scm-container.custom");
            let rootFrame= rootElFrame ? rootElFrame : document.querySelector(".scm-container");
            if(rootFrame){
                rootFrame.style.display= "";
            }
            }; // end of SMARTIFYAPPS.rv.startReviewsImporters

    console.log(SMARTIFYAPPS.rv.performanceNow() + 'smart aliexpress reviews script loaded');
    SMARTIFYAPPS.rv.startReviewsImporter();
} else {
    console.log(SMARTIFYAPPS.rv.performanceNow() + 'skip');
}
